import React, { useState, useEffect } from 'react';
import { storage1, db1 } from '../Database/Movie1';
import { storage2, db2 } from '../Database/Series1';
import { storage3, db3 } from '../Database/Manga1';
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { collection, addDoc, getDocs, getDoc, doc, updateDoc } from "firebase/firestore";
import '../style.css';

const UploadFiles = () => {

  const [isNewSeries, setIsNewSeries] = useState(true);
  const [existingSeriesList, setExistingSeriesList] = useState([]);
  const [selectedSeriesId, setSelectedSeriesId] = useState('');
  const [isMangaMovie, setIsMangaMovie] = useState(false);

  const [isMovie, setIsMovie] = useState(false)
  const [isManga, setIsManga] = useState(false)

  const [selectedGenres, setSelectedGenres] = useState([]);

  const [anime1formtype, setAnime1formtype] = useState({
    titleEng: '',
    titleEngJap: '',
    description: '',
    year: '',
    vidTyp: '',
    imageFile: null,
    videoFile: null,
    genres: selectedGenres,
  });
  const [mangatype, setMangatype] = useState({
    titleEng: '',
    titleEngJap: '',
    description: '',
    year: '',
    imgtyp: '',
    imgPoster: null,
    imgCont: null,
    genres: selectedGenres,
  });



  const handleCheckboxChange = (event) => {
    const { id, checked } = event.target;
    setSelectedGenres((prevGenres) => {
      if (checked) {
        // Add genre if checked
        return [...prevGenres, id];
      } else {
        // Remove genre if unchecked
        return prevGenres.filter((genre) => genre !== id);
      }
    });
  };


  const genres = [
    "Action",
    "Animation",
    "Adventure",
    "Comedy",
    "Drama",
    "Fantasy",
    "Existential Themes",
    "Horror",
    "Magic",
    "Mystery",
    "Coming-of-Age",
    "Romance",
    "Sci-Fi",
    "Slice of Life",
    "Psychological",
    "Thriller",
    "Sports",
    "Historical",
    "Supernatural",
    "Mecha",
    "Military",
    "Shonen",
    "Shojo",
    "Seinen",
    "Josei",
    "Isekai",
    "Martial Arts",
    "Ecchi",
    "Music",
    "Parody",
    "School",
    "Shoujo Ai",
    "Yaoi",
    "Demons",
    "Shounen Ai",
    "Vampire",
    "Yuri",
    "Harem",
    "Reverse Harem",
    "Police",
    "Space",
    "Kids",
    "Dark Fantasy",
    "Crime",
    "Suspence",
    "Tragedy"
  ];


  useEffect(() => {
    if (anime1formtype.vidTyp === 'Movie') {
      setIsMovie(true)
    } else {
      setIsMovie(false)
      if (anime1formtype.vidTyp === 'Manga') {
        setIsManga(true)
      }
      else {
        setIsManga(false)
      }
    }
  }, [anime1formtype.vidTyp])

  // const db = anime1formtype.vidTyp === "Series" ? db2 : (anime1formtype.vidTyp === 'Manga' ? db3 : db1);
  const storage = anime1formtype.vidTyp === "Series" ? storage2 : (anime1formtype.vidTyp === 'Manga' ? storage3 : storage1);

  useEffect(() => {
    if (anime1formtype.vidTyp === 'Series') {
      const fetchSeriesList = async () => {
        const seriesSnapshot = await getDocs(collection(db2, 'seriesContent'));
        // console.log(seriesSnapshot)
        setExistingSeriesList(seriesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
      };
      fetchSeriesList();
    }
  }, [anime1formtype.vidTyp]);

  useEffect(() => {
    if (mangatype.imgtyp === 'Movie') {
      setIsMangaMovie(true);
    } else {
      setIsMangaMovie(false);
    }
  }, [mangatype.imgtyp]);

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setAnime1formtype((prev) => ({
      ...prev,
      [name]: type === 'file' ? files[0] : value,
    }));
  };

  // const handleMangaChange = (e) => {
  //     const { name, value, type, files } = e.target;
  //     setMangatype((prev) => ({
  //         ...prev,
  //         [name]: type === 'file' ? files[0] : value,
  //     }));
  // };

  const uploadFileWithProgress = (file, path) => {
    return new Promise((resolve, reject) => {
      const fileRef = ref(storage, path);
      const uploadTask = uploadBytesResumable(fileRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          console.log(`Upload is ${progress.toFixed(2)}% done for ${file.name}`);
        },
        (error) => reject(error),
        () => {
          getDownloadURL(uploadTask.snapshot.ref).then(resolve);
        }
      );
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let imageURL = anime1formtype.imageFile ? await uploadFileWithProgress(anime1formtype.imageFile, `images/${anime1formtype.imageFile.name}`) : '';
      let videoURL = anime1formtype.videoFile ? await uploadFileWithProgress(anime1formtype.videoFile, `videos/${anime1formtype.videoFile.name}`) : '';

      if (anime1formtype.vidTyp === 'Series') {
        if (isNewSeries) {
          await addDoc(collection(db2, 'seriesContent'), {
            titleEng: anime1formtype.titleEng,
            titleEngJap: anime1formtype.titleEngJap,
            description: anime1formtype.description,
            year: anime1formtype.year,
            vidTyp: anime1formtype.vidTyp,
            imageURL,
            videoURL,
            episodes: [{ videoURL, episodeNumber: 1 }],
            genres: selectedGenres,
            createdAt: new Date(),
          });
        } else {
          const seriesDoc = doc(db2, 'seriesContent', selectedSeriesId);
          const seriesData = (await getDoc(seriesDoc)).data();
          const episodeNumber = seriesData.episodes.length + 1;

          await updateDoc(seriesDoc, {
            episodes: [...seriesData.episodes, { videoURL, episodeNumber }],
          });
        }
      } else if (anime1formtype.vidTyp === 'Manga') {
        await addDoc(collection(db3, 'mangaContent'), {
          titleEng: anime1formtype.titleEng,
          titleEngJap: anime1formtype.titleEngJap,
          description: anime1formtype.description,
          vidTyp: anime1formtype.vidTyp,
          year: anime1formtype.year,
          imageURL,
          createdAt: new Date(),
          genres: selectedGenres,
        });
      } else {
        await addDoc(collection(db1, 'movieContent'), {
          titleEng: anime1formtype.titleEng,
          titleEngJap: anime1formtype.titleEngJap,
          description: anime1formtype.description,
          year: anime1formtype.year,
          vidTyp: anime1formtype.vidTyp,
          imageURL,
          videoURL,
          createdAt: new Date(),
          genres: selectedGenres,
        });
      }

      alert('Data uploaded successfully!');
      setAnime1formtype({ titleEng: '', titleEngJap: '', description: '', year: '', vidTyp: '', imageFile: null, videoFile: null });
      setIsNewSeries(true);
      setSelectedSeriesId('');
    } catch (error) {
      console.error("Error uploading data: ", error);
      alert('Error uploading data');
    }
  };

  return (
    <div className="adminpanel">

      <div className="back-btn">
        <button type='button' onClick={() => { window.history.back(); }}>Back</button>
      </div>

      <form onSubmit={handleSubmit} className="formInputs">

        <div className="content-type-ask">
          <label htmlFor="vidTyp">Content Type:</label>
          <select name="vidTyp" value={anime1formtype.vidTyp} onChange={(e) => { handleChange(e); setIsNewSeries(true); }}>
            <option value="Series">Series</option>
            <option value="Movie">Movie</option>
            <option value="Manga">Manga</option>
          </select>
          <br /><br />
        </div>

        {isMovie ? (<>
          <div className="movie-add">
            <div className="dd">
              <div className='divd'>
                <label htmlFor="titleEng">Title (Eng):</label>
                <input type="text" name="titleEng" value={anime1formtype.titleEng} onChange={handleChange} />
              </div>

              <div className='divd'>
                <label htmlFor="titleEngJap">Title (Jap-Eng):</label>
                <input type="text" name="titleEngJap" value={anime1formtype.titleEngJap} onChange={handleChange} />
              </div>

              <div className='divd'>
                <label htmlFor="description">Description:</label>
                <input type="text" name="description" value={anime1formtype.description} onChange={handleChange} />
              </div>

              <div className='divd'>
                <label htmlFor="year">Year:</label>
                <input type="number" name="year" value={anime1formtype.year} onChange={handleChange} />
              </div>

              <div className='divd'>
                <label htmlFor="imageFile">Image:</label>
                <input type="file" name="imageFile" onChange={handleChange} />
              </div>

              <div className='divd'>
                <label htmlFor="videoFile">Video File:</label>
                <input type="file" name="videoFile" onChange={handleChange} />
              </div>

              <div className='selected-genre'>
                <h3>Selected Genres:</h3>
                <br />
                <ul>
                  {selectedGenres.map((genre) => (
                    <li key={genre}>{genre}</li>
                  ))}
                </ul>
              </div>
            </div>

            <div className="genre">
              <div className="genres">
                {genres.map((genre) => (
                  <div key={genre} className='genre-option'>
                    <label htmlFor={genre}>{genre.charAt(0).toUpperCase() + genre.slice(1)}</label>
                    <input
                      type="checkbox"
                      id={genre}
                      checked={selectedGenres.includes(genre)}
                      onChange={handleCheckboxChange}
                    />
                  </div>
                ))}
              </div>

            </div>
          </div>

        </>) : (<>{
          isManga ? (
            <div className="manga-add">


              <div className="heading-mg-add">
                <h1>Manga</h1>
              </div>
              <div className="adding-manga-sec">

              </div>

            </div>
          ) : (
            <div className="series-add">
              <div className='divds'>
                <label>Is this a new series?</label><br />
                <input type="radio" id='new' name="isNewSeries" checked={isNewSeries} onChange={() => setIsNewSeries(true)} /> <label htmlFor="new">New Series</label> <br />
                <input type="radio" id='existing' name="isNewSeries" checked={!isNewSeries} onChange={() => setIsNewSeries(false)} /> <label htmlFor="existing">Existing Series</label>

              </div>

              {isNewSeries && (<div className="new-series">


                <div className="dd">
                  <div className='divd'>
                    <label htmlFor="titleEng">Title (English):</label>
                    <input type="text" name="titleEng" value={anime1formtype.titleEng} onChange={handleChange} />
                  </div>

                  <div className='divd'>
                    <label htmlFor="titleEngJap">Title (Japanese-English):</label>
                    <input type="text" name="titleEngJap" value={anime1formtype.titleEngJap} onChange={handleChange} />
                  </div>

                  <div className='divd'>
                    <label htmlFor="description">Description:</label>
                    <input type="text" name="description" value={anime1formtype.description} onChange={handleChange} />
                  </div>

                  <div className='divd'>
                    <label htmlFor="year">Year:</label>
                    <input type="number" name="year" value={anime1formtype.year} onChange={handleChange} />
                  </div>

                  <div className='divd'>
                    <label htmlFor="imageFile">Image:</label>
                    <input type="file" name="imageFile" onChange={handleChange} />
                  </div>

                  <div className='divd'>
                    <label htmlFor="videoFile">Video File:</label>
                    <input type="file" name="videoFile" onChange={handleChange} />
                  </div>

                  <div className='selected-genre'>
                    <h3>Selected Genres:</h3>
                    <br />
                    <ul>
                      {selectedGenres.map((genre) => (
                        <li key={genre}>{genre}</li>
                      ))}
                    </ul>
                  </div>
                </div>
                <div className="genre">

                  <div className="genres">
                    {genres.map((genre) => (
                      <div key={genre} className='genre-option'>
                        <label htmlFor={genre}>{genre.charAt(0).toUpperCase() + genre.slice(1)}</label>
                        <input
                          type="checkbox"
                          id={genre}
                          checked={selectedGenres.includes(genre)}
                          onChange={handleCheckboxChange}
                        />
                      </div>
                    ))}
                  </div>

                </div>
                {/* new series end */}

              </div>)}

              {!isNewSeries && (<div className="existing-series">
                <>
                  <select value={selectedSeriesId} onChange={(e) => setSelectedSeriesId(e.target.value)}>

                    <option value="">-Select Series--</option>
                    {existingSeriesList.map(series => <option key={series.id} value={series.id}>{series.titleEng}</option>)}

                  </select>
                  <div>

                    <label htmlFor="videoFile">Video File:</label>
                    <input type="file" name="videoFile" onChange={handleChange} />
                  </div>
                </>
              </div>)}
            </div>


          )
        }  </>)

        }
        <input type='submit' value={'submit'} />

      </form>
    </div>
  );
};

export default UploadFiles;