import './Manga.css'
import imgbillboard from '../../img/One_Piece,_Volume_61_Cover_(Japanese).jpg'

const Manga = () => {
  return (
    <div className='mangas'>
      <div className="manga">
        <div className="sec1">

          <div className="manga-bill-board">

            <div className="manga-box-board">
              <div className="lt-manga">
                <img src={imgbillboard} alt='manga-bill-board' />
              </div>
              <div className="rt-manga">
                <div className="title-eng">One Piece</div>
                <div className="title-jap"> One Piece</div>
                <div className="description">One Piece is a Japanese manga series written and illustrated by Eiichiro Oda which has been translated into various languages and spawned a substantial media franchise, including animated and live action television series, films, video games, and associated music and merchandise. It follows the adventures of the teenaged boy and pirate captain Monkey D. Luffy, whose body gained the properties of rubber after he accidentally ate a supernatural fruit, as he travels the oceans in search of the series' titular treasure and organizes a diverse crew, named the Straw Hats.</div>
                <div className="btn-manga">
                  <div className="anata"></div>
                  <button>View</button>
                </div>
              </div>
            </div>

          </div>

        </div>

        <div className="sec2">
          <div className="all-types-of-manga">


            <div className="manga-list-popular">

              <div className="title-list">
                <h1>Pupular Manga</h1>
              </div>
              <div className="manga-boxes">

                <div className="manga-box">
                  <div className="box-img">
                    <img src={imgbillboard} alt="" />
                  </div>
                  <p>One Piece</p>
                </div>

              </div>
            </div>
          </div>
        </div>

      </div>
    </div>
  )
}

export default Manga