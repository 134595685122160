import React, { createContext, useContext, useState } from "react";

// Create the context
const MoviesContext = createContext();

// Provide the context to children components
export const MoviesProvider = ({ children }) => {
  const [movies, setMovies] = useState([]);
  const [series, setSeries] = useState([]);
  const [isShuffled, setIsShuffled] = useState(false);

  const shuffleArray = (array) => array.sort(() => Math.random() - 2);

  // Shuffle and set movies/series only once
  const initializeData = (movieArray, seriesArray) => {
    if (!isShuffled) {
      setMovies(shuffleArray([...movieArray]));
      setSeries(shuffleArray([...seriesArray]));
      setIsShuffled(true);
    } else {
      setMovies(movieArray);
      setSeries(seriesArray);
    }
  };

  return (
    <MoviesContext.Provider value={{ movies, series, initializeData }}>
      {children}
    </MoviesContext.Provider>
  );
};

// Custom hook to use the context
export const useMoviesContext = () => useContext(MoviesContext);
