import './navbar.css';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import navimg from '../img/navigation-bar.png';
import searchIcon from '../img/searchh.png';
import closeImg from '../img/close.png';
import homeImg from '../img/homegray.png';
import animeImg from '../img/animegray.png';
import mangaImg from '../img/mangagray.png';
import { db1 } from '../Database/Movie1';
import { db2 } from '../Database/Series1';
import { db3 } from '../Database/Manga1';
import { collection, getDocs } from 'firebase/firestore';
import imglogBlack from '../img/icons8-user-96.png';
import imglogoWhite from '../img/icons8-user-96 (1).png';
import Cookies from 'js-cookie';

const NavBar = () => {
    const [navOpen, setNavOpen] = useState(false);
    const [searchOpen, setSearchOpen] = useState(false);
    const [isLogin, setIsLogin] = useState(false);
    const [queryText, setQueryText] = useState('');
    const [results, setResults] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showResults, setShowResults] = useState(false);

    const searchAcrossDatabases = async (searchTerm) => {
        setLoading(true);
        let combinedResults = [];

        try {
            const db1Results = await fetchAllFromDatabase(db1, 'movieContent');
            const db2Results = await fetchAllFromDatabase(db2, 'seriesContent');
            const db3Results = await fetchAllFromDatabase(db3, 'mangaContent');

            combinedResults = [...db1Results, ...db2Results, ...db3Results];

            const searchTermLower = searchTerm.toLowerCase();
            const filteredResults = combinedResults.filter(item =>
                item.titleEng.toLowerCase().includes(searchTermLower) ||
                item.titleEngJap.toLowerCase().includes(searchTermLower)
            );

            const uniqueResults = Array.from(new Map(filteredResults.map(item => [item.id, item])).values());
            setResults(uniqueResults);
        } catch (error) {
            console.error('Error fetching results:', error);
        } finally {
            setLoading(false);
        }
    };

    const fetchAllFromDatabase = async (db, collectionName) => {
        const results = [];
        const snapshot = await getDocs(collection(db, collectionName));
        snapshot.docs.forEach(doc => {
            results.push({ id: doc.id, ...doc.data() });
        });
        return results;
    };

    const handleInputChange = (event) => {
        const newQuery = event.target.value;
        setQueryText(newQuery);
        if (newQuery.trim()) {
            setShowResults(true); // Show results if there's a query
            searchAcrossDatabases(newQuery);
        } else {
            setResults([]); // Clear results if query is empty
            setShowResults(false); // Hide results if query is empty
        }
    };

    const handleFocus = () => {
        if (queryText.trim()) {
            setShowResults(true); // Show results if there's a query
        }
    };

    const handleBlur = () => {
        setTimeout(() => setShowResults(false), 500); // Hide results when input is unfocused
    };

    const resetQuery = () => {
        setQueryText('');
    };

    useEffect(() => {
        const getCookieFromStore = Cookies.get("acsTkn");
        const getCookieFromStore2 = Cookies.get("_tokenResponse");

        if (getCookieFromStore || getCookieFromStore2) {
            setIsLogin(true);
        }
    }, []);

    const sideNavOpen = () => { setNavOpen(!navOpen); }
    const sideNav2 = () => { setSearchOpen(!searchOpen); }

    return (
        <div className="navBar">
            <div className='lp'>
                <div className="left-lp">
                    <div className="logo-lp">
                        <h1>UMI</h1>
                    </div>
                    <div className="links-lp">
                        <Link to={'/'}>Home</Link>
                        <Link to={'/anime'}>Anime</Link>
                        <Link to={'/manga'}>Manga</Link>
                        <Link to={'/special'}>Special</Link>
                    </div>
                </div>

                <div className="right-lp">
                    <div className="search-lp">
                        <input
                            type="text"
                            placeholder="Search"
                            value={queryText}
                            onChange={handleInputChange}
                            onFocus={handleFocus}
                            onBlur={handleBlur}
                        />
                        {loading && <div>Loading...</div>}
                        {showResults && (
                            <div className="box-search">
                                <ul className='results scroll-search' id='resultss'>
                                    {results.map((result) => (
                                        <Link 
                                            onClick={resetQuery} 
                                            to={`/detail/${result.vidTyp.charAt(0).toLowerCase() + result.vidTyp.slice(1)}/${result.id}`} // Updated link structure
                                            className='res' 
                                            key={result.id}>
                                            <img src={result.imageURL} alt="" />
                                            <p>{result.titleEng}</p>
                                        </Link>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </div>
                    <div className="login-lp">
                        {isLogin ? (
                            <Link to={'/uploadfiles'}>
                                <img src={imglogoWhite} height={40} alt="" />
                            </Link>
                        ) : (
                            <Link to={'/login'}>
                                <img src={imglogBlack} height={40} alt="" />
                            </Link>
                        )}
                    </div>
                </div>
            </div>

            <div className="ph">
                <div className="left-ph">
                    <img onClick={sideNavOpen} src={navimg} alt="" height={37} width={30} />
                    <h1>AMI TV</h1>
                </div>
                <div className="right-ph">
                    <img onClick={sideNav2} src={searchIcon} alt="" height={36} />
                    <div className="login-lp">
                        {isLogin ? (
                            <Link to={'/uploadfiles'}>
                                <img src={imglogoWhite} height={40} alt="" />
                            </Link>
                        ) : (
                            <Link to={'/login'}>
                                <img src={imglogBlack} height={40} alt="" />
                            </Link>
                        )}
                    </div>
                </div>
            </div>

            {navOpen && (
                <div className="sideNav">
                    <div className="up-part">
                        <img onClick={sideNavOpen} src={closeImg} height={40} alt="" />
                    </div>
                    <div className="dn-part">
                        <div className="link">
                            <div className="lnk">
                                <img src={homeImg} height={28} alt="" />
                                <Link onClick={sideNavOpen} to={'/'}>Home</Link>
                            </div>
                            <div className="lnk">
                                <img src={animeImg} height={28} alt="" />
                                <Link onClick={sideNavOpen} to={'/anime'}>Anime</Link>
                            </div>
                            <div className="lnk">
                                <img src={mangaImg} height={28} alt="" />
                                <Link onClick={sideNavOpen} to={'/manga'}>Manga</Link>
                            </div>
                            <div className="lnk">
                                <img src={animeImg} height={28} alt="" />
                                <Link onClick={sideNavOpen} to={'/special'}>Special</Link>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            {searchOpen && (
                <div className="sideNav">
                    <div className="up-part">
                        <img onClick={sideNav2} src={closeImg} height={40} alt="" />
                    </div>
                    <div className="searchBox">
                        <div className="searchbox">
                            <input
                                type="text"
                                placeholder="Search"
                                value={queryText}
                                onChange={handleInputChange}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                            />
                            {loading && <div>Loading...</div>}
                            {showResults && (
                                <div className="box-search">
                                    <ul className='results scroll-search' id='resultss'>
                                        {results.map((result) => (
                                            <Link 
                                                onClick={() => {resetQuery(); sideNav2();}} 
                                                to={`/detail/${result.vidTyp.charAt(0).toLowerCase() + result.vidTyp.slice(1)}/${result.id}`} // Updated link structure
                                                className='res' 
                                                key={result.id}>
                                                <img src={result.imageURL} alt="" />
                                                <p>{result.titleEng}</p>
                                            </Link>
                                        ))}
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default NavBar;
