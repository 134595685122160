import React, { useEffect, useState, useRef } from 'react';
import FetchMovies from '../../fetching/FetchMovie';
import FetchSeries from '../../fetching/FetchSeries';
import { Link } from 'react-router-dom';

const Anime = () => {
    const [movies, setMovies] = useState([]);
    const [series, setSeries] = useState([]);
    const [visibleCount, setVisibleCount] = useState(6); // Number of visible cards
    const moviesRef = useRef(null);
    const seriesRef = useRef(null);

    const shuffleArray = (array) => array.sort(() => Math.random() - 0.5);

    useEffect(() => {
        const getMovies = async () => {
            const movieArray = await FetchMovies();
            setMovies(movieArray);
        };

        const getSeries = async () => {
            const seriesArray = await FetchSeries();
            setSeries(seriesArray);
        };

        getMovies();
        getSeries();
        shuffleArray(movies);
        shuffleArray(series);
    }, []);

    const handleScroll = (event) => {
        const { scrollLeft, scrollWidth, clientWidth } = event.target;
        if (scrollLeft + clientWidth >= scrollWidth - 0.8) {
            setVisibleCount((prev) => prev + 6); // Load 8 more cards on scroll
        }
    };

    return (
        <div>
            <div className="Movies">
                <div className="heading">
                    <h1>Anime Movie</h1>
                    <hr /> <br />
                </div>
                <div className="animesMovie" ref={moviesRef} onScroll={handleScroll}>
                    {movies.slice(0, visibleCount).reverse().map((movie) => (
                        <Link to={`/detail/movie/${movie.id}`} key={movie.id} className="outer-box">
                            <div className="innerbox">
                                <img src={movie.imageURL} alt={`${movie.titleEng} Poster`} className="movie-poster" />
                            </div>
                            <p>{movie.titleEng}</p>
                        </Link>
                    ))}
                </div>
                <br /><br /><br />
            </div>

            <div className="Series">
                <div className="heading">
                    <h1>Anime Shows</h1>
                    <hr /><br />
                </div>
                <div className="animesMovie" ref={seriesRef} onScroll={handleScroll}>
                    {series.slice(0, visibleCount).reverse().map((seriesItem) => (
                        <Link to={`/detail/series/${seriesItem.id}`} key={seriesItem.id} className="outer-box">
                            <div className="innerbox">
                                <img src={seriesItem.imageURL} alt={`${seriesItem.titleEng} Poster`} className="movie-poster" />
                            </div>
                            <p>{seriesItem.titleEng}</p>
                        </Link>
                    ))}
                </div>
            </div>
        </div>
    );
}

export default Anime;
