import React from 'react'
import './MS.css'

const MangaStream = () => {



    
    return (
        <div className='manga-stream1'>
            
        </div>
    )
}

export default MangaStream