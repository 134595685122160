import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import FetchMovies from '../../fetching/FetchMovie';
import FetchSeries from '../../fetching/FetchSeries';
import './Watch.css'

const Stream = () => {
  const { id, type } = useParams(); // Access the id parameter from the URL

  const [movie, setMovie] = useState(null);

  useEffect(() => {

    if (type === 'movie') {
      const fetchMovieDetails = async () => {
        const movies = await FetchMovies(); // Get all movies
        const selectedMovie = movies.find((movie) => movie.id === id); // Find movie by id
        setMovie(selectedMovie);
      };

      fetchMovieDetails();

    } else if (type === 'series') {
      const fetchSeriesDetails = async () => {
        const movies = await FetchSeries(); // Get all movies
        const selectedMovie = movies.find((movie) => movie.id === id); // Find movie by id
        setMovie(selectedMovie);
      };

      fetchSeriesDetails();
    }





  }, [id, type]);

  if (!movie) return <p>Loading...</p>;

  return (
    <div className='watch'>

        <div className="back-btn">
          <button type='button' onClick={() => { window.history.back(); }}>Back</button>
        </div>
      <div className="watch-cnt">
        {movie.videoURL && (
          <video id='vid' controls disablePictureInPicture controlsList="nodownload noremoteplayback" className="watch-video" autoPlay autoFocus>
            <source src={movie.videoURL} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        )}
      </div>
    </div>
  )
}

export default Stream